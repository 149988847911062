import CLNT1 from '../../img/client-one.jpg'
import CLNT2 from '../../img/client-two.jpg'
import CLNT3 from '../../img/client-three.jpg'
import CLNT4 from '../../img/client-four.jpg'
import CLNT5 from '../../img/client-five.jpg'
import CLNT6 from '../../img/client-six.jpg'

import './EndoMe.css'

const endos = [
  {
    id: 1,
    src: CLNT1,
    name: "S. Serhiy",
    review: `Elnol var fantastisk at arbejde med og gjorde, hvad jeg bad om. Jeg vil også gerne nævne, hvordan jeg er taknemmelig for hans fleksibilitet. Jeg er så glad for slutresultatet. Tak`
  }, 
  {
    id: 2,
    src: CLNT2,
    name: "L. Limam",
    review: `Min første gang, jeg arbejdede med Elnol, var det sidste forår. Og alt hvad jeg kan sige er, at han er en tillidsfuld person, reaktiv og som tilbyder relevante løsninger. Mit råd ville være: 'Tøv ikke med'.`
  },
  {
    id: 3,
    src: CLNT3,
    name: "M. Krakin",
    review: `Fremragende arbejde og ja, fuldt ud anbefalet. Hans arbejde er hurtigt, ikke så dyrt, og du vil føle dig tilfreds med det. Alt var pænt, overskueligt og nemt at have med at gøre!
    Tøv ikke og start med Elnol!`
  },
  {
    id: 4,
    src: CLNT4,
    name: "L. Ismaj",
    review: `God kommunikation, hurtige resultater. Meget behagelig at arbejde med. Opmærksomhed på detaljer med kreativitet. Igen, mange tak, Elnol, og jeg vil helt sikkert arbejde sammen med dig igen.
    
    Hej!`
  },
  {
    id: 5,
    src: CLNT5,
    name: "S. John",
    review: `Lad os være ærlige, der er tonsvis af kilder for dig at starte med din online-forretning, men Elnol vil guide dig til den bedste, nemmeste og mest bekvemme måde at begynde at opbygge enhver online-forretning på.
    `
  },
  {
    id: 6,
    src: CLNT6,
    name: "D. Lev",
    review: `
    Jeg anbefaler dig bestemt at starte din virksomhed med Elnol!
    Han gør alt sit arbejde til tiden og undgår kompleksiteten! Selvom du er helt ny inden for webudvikling, kan han få dig til at føle, som det har været årtier for dig på dette felt.
    `
  }
]

export default function EndoMe() {
  return (
    <div className="endor page">
      <h2>Vidnesbyrd</h2>
      <div className="endor-container">

        <div className="endor-one">

          <div className="endor-card">
            <div className="person-review">
              <p>{endos[0].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT1} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[0].name}</p>
              </div>
            </div>
          </div>

          <div className="endor-card">
            <div className="person-review">
              <p>{endos[4].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT5} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[4].name}</p>
              </div>
            </div>
          </div>

          <div className="endor-card">
            <div className="person-review">
              <p>{endos[2].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT3} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[2].name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="endor-two">
          <div className="endor-card">
            <div className="person-review">
              <p>{endos[3].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT4} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[3].name}</p>
              </div>
            </div>
          </div>

          <div className="endor-card">
            <div className="person-review">
              <p>{endos[1].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT2} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[1].name}</p>
              </div>
            </div>
          </div>

          <div className="endor-card">
            <div className="person-review">
              <p>{endos[5].review}</p>
            </div>
                
            <div className="person-info">
              <div className="person-img">
                <img src={CLNT6} alt=""/>
              </div>

              <div className="person-name">
                <p>{endos[5].name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
