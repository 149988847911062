import { BrowserRouter, Routes, Route } from 'react-router-dom'

import AutoTopScroll from './components/AutoTopScroll'

import Header from './components/Header/Header'
import Nav from './components/Nav/Nav'
import Social from './components/Social/Social'
import Footer from './components/Footer/Footer'

import Home from './pages/home/Home'
import AboutMe from './pages/aboutme/AboutMe'
import EndoMe from './pages/endome/EndoMe'
import ContactMe from './pages/contactme/ContactMe'

import './App.css'

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AutoTopScroll />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/om" element={<AboutMe />} />
          <Route path="/vidnesbyrd" element={<EndoMe />} />
          <Route path="/kontakt" element={<ContactMe />} />
        </Routes>

        <Header />
        <Nav />
        <Social />
        <Footer />
        
      </BrowserRouter>
    </div>
  )
}
