import { BsCode, BsSuitHeartFill } from 'react-icons/bs'

import './Footer.css'

export default function Footer() {
  return (
    <div className="footer-ft">
      <div className="first-ft">
        <div className="name-ft">
          <p>Trænger dit projekt til noget kærlighed?<br/> Jeg vil meget gerne hjælpe!</p>
        </div>
        <div className="line-ft">
        </div>
        <div className="let-me-ft">
          <p>Lad mig vide.</p>
        </div>
      </div>
      
      <div className="second-ft">
        <div className="contact-ft">
          <div className="email-ft">
            contact@elnol.com
          </div>

          <div className="dot-ft">
          </div>

          <div className="fon-ft">
            55 23 73 91
          </div>
        </div>

        <div className="made-ft">
          <div><BsCode /></div>

          <div>med</div>

          <div><BsSuitHeartFill /></div>

          <div>af &#169; Elnol</div>
        </div>
      </div>
      
    </div>
  )
}
