import './Home.css'

export default function Home() {
  return (
    <div className="home page">
      <div className="me-here">
      Jeg er her for at hjælpe dig med<br/> <span>at gøre</span> dine  <div className="dream"><div>drømme</div><div>projekter</div></div> til <span>virkelighed</span>
      </div>

      <div className="small">
      Hej! Jeg hedder Tim Limame (oks, <span>Elnol</span>)
      <br/>En ambitiøs problemløser, 
        med passion for online-virksomheder, og som gerne vil indgå i et hold af ligesindede<br />
        Jeg kan beskrive mig selv som en nysgerrig dataanlytiker med et stærkt fundament i 
        matematik<br/> og programmeringslogik<br />
        Nu søger jeg aktivt efter en stilling med mulighed for vækst og et bredere 
        læringspotentiale, der<br/> vil bringe min tekniske baggrund til det næste niveau
      </div>
    </div>
  )
}
