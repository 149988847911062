import './AboutMe.css'

export default function AboutMe() {
  return (
    <section className="about page">
      <h2>Om mig</h2>
      <div className="about-container">
        <div className="p-o">
          <p>
          IT-domæne er min passion! Jeg er .NET udvikler med 2 års erfaring inden for Engineering domænet<br />
          Jeg søger at forfølge en mulighed med en organisation, der vil udnytte og forbedre min viden inden for dataanalyse, og som vil give mig væsentlige vækstmuligheder<br />
          Min største styrke er at hjælpe de søgende med at finde løsninger på deres problemer ved at levere den passende analyse.
          Tilfældigvis kan jeg sige, at jeg er betaget af at forblive organiseret og forsøge at skabe et miljø fyldt med muligheder, lykke og velvære.
          </p>
        </div>

        <div className="p-t">
          <p>
          <br />Gennem hele mine første 3 år på universitetet blev jeg fast knyttet til alle ideer til programmer, jeg kunne få, og flyttede fra programmet "HelloWorld.py" hele vejen til den hjemmeside, jeg lavede til min kones virksomhed<br /> Jeg startede min karriere med at lære C og Java, derefter Python, og udforske alle bøger, jeg kunne finde relateret til dataanalyse og statistik<br/>Jeg var hooked.
          Jeg nyder at møde nye mennesker, nye livsstile og lære af de andres erfaringer.
          </p>
        </div>
        
        <div className="about-me">
          <div className="about-me-foto"></div>
        </div>
      </div>
    </section>
  )
}
