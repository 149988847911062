import { FaMapMarkerAlt } from 'react-icons/fa'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { useRef } from 'react'
import emailjs from 'emailjs-com'


import './ContactMe.css'

const inputs = document.querySelectorAll('input')

if (inputs) {
  inputs.forEach(el => {
    el.addEventListener('blur', (e) => {
      if (e.target.value) {
        e.target.classList.add('fun');
      } else {
        e.target.classList.remove('fun')
      }
    })
  })
}

export default function ContactMe() {

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4h09sco', 'template_kf0dbbp', form.current, 'HsiQNB1ouEgYWugiq')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()//To clear the form after being sent!
  };
  return (
    
    <div className="contact page">
      <div className="left">
        <div>
          <a href="https://web.whatsapp.com/send?phone=+4555237391">
            <BsWhatsapp />
          </a>
          <span>+45 55 23 73 91</span>
        </div>

        <div>
          <a href="mailto:contact@elnol.com">
            <AiOutlineMail />
          </a>
          <span>contact@elnol.com</span>
        </div>
        
        <div>
          <a className="my-map">
            <FaMapMarkerAlt />
          </a>
          <span>Danmark, Sjælland</span>
        </div>
        
      </div>

      <div className="right">
        <form ref={form} className="body" onSubmit={sendEmail}>
          
          <label className="custom-field three">
            <input name="name" type="text" required/>
            <span className="placeholder">Dit Navn</span>
            <span className="border"></span>
          </label>

          <label className="custom-field three">
            <input name="email" type="email" required/>
            <span className="placeholder">Din Email</span>
            <span className="border"></span>
          </label>

          <div className="custom-field three">
            <textarea name="msg" placeholder="Skriv din besked her..."></textarea>
          </div>
          
          <div className="custom-field" >
            <button type="submit">Send</button>
          </div>
          
        </form>

      </div>

    </div>
  )
}
