import { Link } from 'react-router-dom'

import {AiFillHome} from 'react-icons/ai'
import { FaBookOpen } from 'react-icons/fa'
import { SiAboutdotme } from 'react-icons/si'
import { BsPeopleFill } from 'react-icons/bs'
import { RiMessage2Fill } from 'react-icons/ri'
import './Nav.css'

export default function Nav() {
  return (
    <nav>
      <Link to="/"><AiFillHome /></Link>
      <Link to="/om"><SiAboutdotme /></Link>
      <Link to="/vidnesbyrd"><BsPeopleFill /></Link>
      <Link to="/kontakt"><RiMessage2Fill /></Link>
    </nav>
  )
}
