import { Link } from 'react-router-dom'

import './Header.css'

export default function Header() {

  return (
    <header className="header">
      <div className="cluster">
        <Link to="/">
          <div className="logo">
            <p className="e">H</p>
            <p className="f-l">O</p>
            <p className="n">U</p>
            <p className="o">N</p>
            <p className="s-l">E</p>
          </div>
        </Link>
        <div className="talk">
          <Link to="/kontakt" className="btn">Lad os Tale</Link>
        </div>

        </div>
    </header>
  )
}
