import { AiFillLinkedin, AiOutlineGithub, AiOutlineTwitter } from 'react-icons/ai'
import './Social.css'

export default function Social() {
  return (
    <div className="social" id="social">
      <a href="https://github.com/lim-elnoll" target="_blank"><AiOutlineGithub /></a>
      <a href="https://www.linkedin.com/in/lim-elnoll/" target="_blank"><AiFillLinkedin /></a>
      <a href="https://twitter.com/lim_elnoll" target="_blank"><AiOutlineTwitter /></a>
    </div>
  )
}
